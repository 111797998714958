/** Styles to view **/
import { StyleSheet, Dimensions } from 'react-native';

/** Import Constants **/
import colors from '../../constants/styles/colors';
/** Create an object style **/
const screenWidth = Dimensions.get('window').width;
const marginCustom = screenWidth > 768 ? '0%' : '60%';
const styles = StyleSheet.create({
  modal: {
    margin: 0,
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderWidth: 0,
    paddingRight: 5,
    paddingLeft: 5,
    backgroundColor: colors.white,
  },
  buttonClose: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: '10%',
    borderRadius: 30,
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonTextTop: {
    color: colors.white,
    fontSize: 3,
  },
  buttonTextBottom: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 14,
  },
  modalBackground: {
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: 10,
    paddingBottom: 20,
    marginTop: marginCustom,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: colors.primary,
    paddingTop: 20,
    paddingRigth: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderTopEndRadius: 10,
  },
  modalTitle: {
    color: colors.white,
    fontWeight: 'bold',
    fontSize: 14,
  },
  modalButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
    borderWidth: 0,
    borderRadius: 8,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    backgroundColor: colors.primary,
  },
  containerDetail: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 10,
    marginBottom: 20,
    paddingRight: 20,
    paddingLeft: 20,
  },
  modalLabel: {
    color: colors.gray,
    fontSize: 14,
    marginTop: 10,
  },
  modalLabelValue: {
    color: colors.black,
    fontWeight: 'bold',
    fontSize: 14,
  },
  icon: {
    textAlign: 'center',
  },
});

/** Export component styles **/
export default styles;
