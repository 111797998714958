/** Import React **/
import React, { Component } from 'react';
import { Platform, View } from 'react-native';

/** Import Redux **/
import { connect } from 'react-redux';

/** Import SnackBar **/
import SnackBar from 'react-native-snackbar-component';

/** Import React Navigation **/
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { TransitionPresets } from '@react-navigation/stack';

// Device Detect
import {
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isChromium,
  isEdgeChromium,
  isMobileSafari,
  osName,
} from 'react-device-detect';

/** Import components **/
import AuthLoadingScreen from '../components/authLoading';
import TopHeader from '../components/topHeader';

/** Import Constants **/
import colors from '../constants/styles/colors';
import { routes, linking } from '../constants/routes/routes';
import styles from './style';
import strings from './strings';
import drawerStyles from './customDrawerContent/style';

/** Import Interfaces **/
import { Auth, SnackBarData } from '../interfaces';

/** Import views auth **/
import LoginScreen from '../views/login';

/** Import views app **/
import ProductDetailScreen from '../views/productDetail';
import ProductImageGalleryScreen from '../views/productImageGallery';
import ShoppingCartDetailScreen from '../views/shoppingCartDetail';
import ProfileScreen from '../views/profile';
import ShoppingHistoryScreen from '../views/shoppingHistory';
import ShoppingHistoryDetailScreen from '../views/shoppingHistoryDetail';
import PortfolioScreen from '../views/portfolio';
import DeliveryHistoryScreen from '../views/deliveryHistory';

/** Import views common **/
import CompaniesListScreen from '../views/companiesList';
import BranchOfficesListScreen from '../views/branchOfficesList';

/** Import PropTypes **/
import { AuthPropTypes } from '../propTypes';

/** Import Navigators **/
import CustomDrawerContent from './customDrawerContent';
import TabNavigator from './TabNavigator';
import LoginScreenWhatsapp from '../views/loginWhatsapp';
import { cleanAndLogout } from '../store';
import ShoppingCartScreen from '../views/shoppingCart';
import CheckoutScreen from '../views/checkout';

declare let global: any;

const Stack = createStackNavigator();

let initialRouteNameValue = routes.order;

function AuthStack() {
  return (
    <Stack.Navigator
      initialRouteName={routes.login}
      headerMode={'screen'}
      screenOptions={() => ({
        ...TransitionPresets.SlideFromRightIOS,
      })}
    >
      <Stack.Screen
        name={routes.login}
        component={LoginScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.transparent} />,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.loginWhatsapp}
        component={LoginScreenWhatsapp}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.transparent} />,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.companiesList}
        component={CompaniesListScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.primary} />,
          gestureEnabled: false,
          ...TransitionPresets.ScaleFromCenterAndroid,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.branchOfficesList}
        component={BranchOfficesListScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.primary} />,
          gestureEnabled: false,
          title: 'Portal de Clientes',
        }}
      />
    </Stack.Navigator>
  );
}

const Drawer = createDrawerNavigator();

function AppDrawer() {
  return (
    <Drawer.Navigator
      initialRouteName={initialRouteNameValue}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      drawerStyle={drawerStyles.container}
      drawerContentOptions={{ activeTintColor: colors.white }}
      drawerType={'back'}
    >
      <Drawer.Screen name={routes.order} component={TabNavigator} options={{ title: 'Portal de Clientes' }} />
      <Drawer.Screen name={routes.profile} component={ProfileScreen} options={{ title: 'Portal de Clientes' }} />
      <Drawer.Screen
        name={routes.shoppingHistory}
        component={ShoppingHistoryScreen}
        options={{ title: 'Portal de Clientes' }}
      />
      <Drawer.Screen name={routes.portfolio} component={PortfolioScreen} options={{ title: 'Portal de Clientes' }} />
      <Drawer.Screen
        name={routes.deliveryHistory}
        component={DeliveryHistoryScreen}
        options={{ title: 'Portal de Clientes' }}
      />
    </Drawer.Navigator>
  );
}

function AppStack() {
  return (
    <Stack.Navigator
      initialRouteName={routes.order}
      headerMode={'screen'}
      detachInactiveScreens={true}
      screenOptions={() => ({
        ...TransitionPresets.FadeFromBottomAndroid,
      })}
    >
      <Stack.Screen
        name={routes.order || routes.portfolio}
        component={AppDrawer}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.transparent} />,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.productsDetail}
        component={ProductDetailScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.white} />,
          ...TransitionPresets.ScaleFromCenterAndroid,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.productImageGallery}
        component={ProductImageGalleryScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.white} />,
          ...TransitionPresets.ScaleFromCenterAndroid,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.shoppingCart}
        component={ShoppingCartScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.white} />,
          ...TransitionPresets.ScaleFromCenterAndroid,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.checkout}
        component={CheckoutScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.white} />,
          ...TransitionPresets.ScaleFromCenterAndroid,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.shoppingHistoryDetail}
        component={ShoppingHistoryDetailScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.white} />,
          ...TransitionPresets.ScaleFromCenterAndroid,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.shoppingCartDetail}
        component={ShoppingCartDetailScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.transparent} />,
          gestureEnabled: false,
          ...TransitionPresets.ScaleFromCenterAndroid,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.drawerCompaniesList}
        component={CompaniesListScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.primary} />,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.drawerBranchOfficesList}
        component={BranchOfficesListScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.primary} />,
          title: 'Portal de Clientes',
        }}
      />
      <Stack.Screen
        name={routes.deliveryHistory}
        component={DeliveryHistoryScreen}
        options={{
          header: (props) => <TopHeader {...props} backgroundColor={colors.primary} />,
          title: 'Portal de Clientes',
        }}
      />
    </Stack.Navigator>
  );
}

interface Props {
  auth?: Auth;
  snackBar?: SnackBarData;
}

interface State {
  size: 'small' | 'large';
  isLoading: boolean;
}

/**
 * Class to export AppNavigator
 *
 * @class AppNavigator
 * @extends {Component<Props, State>}
 */
class AppNavigator extends Component<Props, State> {
  public static defaultProps = {};
  public static propTypes = {};

  /**
   *Validate first screen
   */

  _checkFirstEntry = (props: Props) => {
    if (
      props.auth?.current_rshop?.ecommerce_orders === 'N' ||
      (props.auth?.current_rshop?.ecommerce_wallet === 'Y' &&
        props.auth?.current_rshop?.ecommerce_orders === 'N' &&
        !props.auth?.current_rshop?.has_rsales_operation)
    ) {
      return routes.portfolio;
    } else if (
      props.auth?.current_rshop?.ecommerce_orders === 'Y' ||
      props.auth?.current_rshop?.ecommerce_orders === null ||
      props.auth?.current_rshop?.ecommerce_wallet === 'N' ||
      !props.auth?.current_rshop?.has_rsales_operation
    ) {
      return routes.order;
    } else {
      return routes.order;
    }
  };
  /**
   *Creates an instance of AppNavigator.
   * @param {*} props
   * @memberof AppNavigator
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      size: 'large',
      isLoading: true,
    };
    global.csrftoken = this.props.auth?.csrftoken;
    global.sessionid = this.props.auth?.sessionid;
    global.apply_taxes = this.props.auth?.current_rshop?.apply_taxes;
    global.apply_charge_values_to = this.props.auth?.current_rshop?.apply_charge_values_to;
  }

  /**
   * Validate user and app state
   *
   * @memberof AppNavigator
   */
  componentDidMount() {
    if (Platform.OS === 'web') {
      try {
        if (location.pathname.split('/')[1] === linking.config.screens.LoginScreenWhatsapp.split('/')[0]) {
          cleanAndLogout();
        }
      } catch (e) {}
    }

    this._getCurrentAppState(true);
  }

  /**
   * componentDidUpdate
   *
   * @param {*} nextProps
   * @memberof SingleDatepicker
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.auth?.csrftoken !== prevProps.auth?.csrftoken) {
      global.csrftoken = this.props.auth?.csrftoken;
      global.sessionid = this.props.auth?.sessionid;
    }
  }

  /**
   * Fetch the token from storage then navigate to our appropriate place
   *
   * @memberof AppNavigator
   */
  _getCurrentAppState = (setValue: boolean) => {
    /** This will switch to the App screen or Auth screen and this loading screen will be unmounted and thrown away **/
    if (this.props.auth?.is_logged) {
      if (setValue) {
        this.setState({ isLoading: false });
      }
      return true;
    } else {
      if (setValue) {
        this.setState({ isLoading: false });
      }
      return false;
    }
  };

  /**
   * Detect if the device is compatible with the application
   */
  isAllowedDevice = () => {
    return true;
    return (
      Platform.OS !== 'web' ||
      isChrome ||
      isFirefox ||
      isSafari ||
      isOpera ||
      isChromium ||
      isEdgeChromium ||
      isMobileSafari
    );
  };

  /**
   * Render not allowed device
   */
  renderNotAllowedDevice = () => {
    if (osName === 'Android') {
      location.href = 'googlechrome://navigate?url=[URL]'
        .replace('[URL]', location.href)
        .replace('https://', '')
        .replace('http://', '');
    }

    const redirectUrl = 'http://easypedido.com/navegador-no-compatible';

    setTimeout(() => {
      location.href = redirectUrl;
    }, 3000);

    return (
      <View style={styles.container}>
        <span>{strings.notAllowedDeviceMessage}</span>
        <a href={redirectUrl}>{strings.notAllowedDeviceLinkMessage}</a>
      </View>
    );
  };

  /**
   * Remder App Navigator
   *
   * @returns
   * @memberof AppNavigator
   */
  render() {
    if (this.state.isLoading) {
      return <AuthLoadingScreen size={this.state.size} />;
    } else {
      if (!this.isAllowedDevice()) {
        return this.renderNotAllowedDevice();
      } else if (this._getCurrentAppState(false)) {
        initialRouteNameValue = this._checkFirstEntry(this.props);
        return (
          <View style={styles.container}>
            <AppStack />
            <SnackBar
              visible={this.props.snackBar?.visible}
              textMessage={this.props.snackBar?.textMessage}
              actionHandler={this.props.snackBar?.actionHandler}
              actionText={this.props.snackBar?.actionText}
              position={this.props.snackBar?.position}
              top={this.props.snackBar?.top}
              bottom={this.props.snackBar?.bottom}
              autoHidingTime={this.props.snackBar?.autoHidingTime}
              backgroundColor={this.props.snackBar?.backgroundColor}
              accentColor={this.props.snackBar?.accentColor}
              messageColor={this.props.snackBar?.messageColor}
              containerStyle={this.props.snackBar?.containerStyle}
            />
          </View>
        );
      } else {
        return (
          <View style={styles.container}>
            <AuthStack />
            <SnackBar
              visible={this.props.snackBar?.visible}
              textMessage={this.props.snackBar?.textMessage}
              actionHandler={this.props.snackBar?.actionHandler}
              actionText={this.props.snackBar?.actionText}
              position={this.props.snackBar?.position}
              top={this.props.snackBar?.top}
              bottom={this.props.snackBar?.bottom}
              autoHidingTime={this.props.snackBar?.autoHidingTime}
              backgroundColor={this.props.snackBar?.backgroundColor}
              accentColor={this.props.snackBar?.accentColor}
              messageColor={this.props.snackBar?.messageColor}
              containerStyle={this.props.snackBar?.containerStyle}
            />
          </View>
        );
      }
    }
  }
}

AppNavigator.propTypes = {
  auth: AuthPropTypes,
};

AppNavigator.defaultProps = {};

/** Export component AppNavigator and middleware **/
export default connect((state) => ({ ...state }))(AppNavigator);
