import { urlBase, waUrlBase, waNumber } from './constants';

export const urlRootGalleryUrl = 'https://rsales-multimedia.azurewebsites.net/api/proxy/';
export const urlLogin = `${waUrlBase}/signin-web-app/`;
export const urlLoginWhatsapp = `${waUrlBase}/signin-whatsapp/`;
export const urlGenerateSigninCode = `${waUrlBase}/generate-signin-app-code/`;
export const urlLogout = `${waUrlBase}/signout-whatsapp/`;
export const urlPasswordChange = `${urlBase}/password-change-community/[pk]/`;
export const urlHome = `${urlBase}/[rshop_id]/home-community/`;
export const urlProductList = `${urlBase}/[rshop_id]/product/list-community-new/`;
export const urlProductCategoryFilters = `${urlBase}/[rshop_id]/product/category-filters/`;
export const urlFavoritesList = `${urlBase}/[rshop_id]/favorites/list-community-new/`;
export const urlToogleFavorite = `${urlBase}/[rshop_id]/toogle-favorite-community/`;
export const urlShoppingcartList = `${urlBase}/[rshop_id]/shopping-cart/list-community/`;
export const urlShoppingcartAddItem = `${urlBase}/[rshop_id]/shopping-cart/add-community/`;
export const urlShoppingcartRemoveItem = `${urlBase}/[rshop_id]/shopping-cart/delete-community/`;
export const urlShoppingcartOrder = `${urlBase}/[rshop_id]/shopping-cart/order-community/`;
export const urlShoppinghistoryList = `${urlBase}/[rshop_id]/orders/tracking-summary-community/`;
export const urlShoppinghistoryDetail = `${urlBase}/[rshop_id]/orders/tracking-detail-community/`;
export const urlResetPassword = `${urlBase}/users/password-generate-app/`;
export const urlImageGalleryThumbs = '[company_code]/productos/[product_code]/thumbs/P.jpg';
export const urlImageGalleryMaster = '[company_code]/productos/[product_code]/master/P.jpg';
export const urlImageGalleryProducts = `${urlBase}/[rshop_id]/product-images/`;
export const urlOpenWhatsapp = `https://api.whatsapp.com/send?phone=${waNumber}`;
export const urlShoppingMassivecartRemoveItem = `${urlBase}/[rshop_id]/shopping-cart/delete-massive-community/`;
export const urlDeliveryhistoryList = `${urlBase}/[rshop_id]/delivery/delivery-history/`;
export const urlDeliveryhistoryState = `${urlBase}/[rshop_id]/activities/web/statesEP/list/`;
export const urlEditProfile = `${urlBase}/[rshop_id]/edit-profile/`;
export const urlEditProfilePhone = `${urlBase}/[rshop_id]/edit-profile-phone/`;
export const urlResendTokenProfile = `${urlBase}/[rshop_id]/resend-token-profile/`;
